<template>
  <div class="">
    <div class="tab-list">
      <div
        :class="activenum == item.id ? 'activeclass' : ''"
        v-for="(item, index) in tablist"
        :key="index"
        @click="changetab(item.path)"
      >
        <span>{{ item.name }}</span>
      </div>
    </div>
    <el-card class="box-card">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="公司LOGO:"
          prop="companyAvatar"
          :rules="[{ required: true, message: '请上传公司LOGO' }]"
        >
          <el-upload
            action="https://freemen.work/user/file/upload/"
            list-type="picture-card"
            :file-list="fileList"
            :limit="2"
            :on-success="handleSuccess"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="updialogVisible">
            <img
              width="100%"
              :src="
                'https://freemen.work/user/file/view/' + ruleForm.companyAvatar
              "
              alt=""
            />
          </el-dialog>
          <div class="imgts font14">
            只能上传jpg/png格式文件，文件不能超过3M
          </div>
        </el-form-item>
        <el-form-item
          label="公司全称:"
          prop="companyName"
          :rules="[{ required: true, message: '请输入公司全称' }]"
        >
          <el-input
            v-model="ruleForm.companyName"
            placeholder="请输入公司全称"
            style="width: 500px"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公司简称:"
          prop="shortName"
          :rules="[{ required: true, message: '请输入公司简称' }]"
        >
          <el-input
            v-model="contentNeedReview.shortName"
            placeholder="请输入公司简称"
            style="width: 500px"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="人员规模 :"
          prop="companySize"
          :rules="[{ required: true, message: '请选择人员规模' }]"
        >
          <el-select
            v-model="contentNeedReview.companySize"
            placeholder="请选择人员规模"
            style="width: 500px"
          >
            <el-option
              v-for="item in sizeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司类型 :" prop="companyType">
          <el-select
            v-model="contentNeedReview.companyType"
            placeholder="请选择公司类型"
            style="width: 500px"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司福利 :" prop="companyWelfare">
          <el-select
            v-model="contentNeedReview.companyWelfare"
            multiple
            placeholder="请选择公司福利"
            style="width: 500px"
          >
            <el-option
              v-for="item in weifaryOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司介绍：" prop="companyDescription">
          <el-input
            type="textarea"
            :autosize="{ minRows: 8, maxRows: 10 }"
            show-word-limit
            placeholder="请输入公司介绍"
            v-model="ruleForm.companyDescription"
            style="width: 500px"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog
        title="  您好："
        :visible.sync="warnDialogVisible"
        :modal-append-to-body="true"
        :append-to-body="true"
        width="30%"
        padding-top="20px"
        style="border-radius: 4px; white-space: pre;"
    >
      <div style="padding: 0 20px">
        <p style="text-align: left; font-size: 17px; padding-top: 20px; white-space: normal;">
          您提交的修改需要被审核，是否确认提交当前修改？
        </p>

      </div>
      <span slot="footer" class="dialog-footer">
            <el-button @click="warnDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="toSava('ruleForm')">确 定</el-button>
          </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
      tablist: [
        { name: "企业基本信息", id: 1, path: "" },
        { name: "公司相册", id: 2, path: "/photoalbum" },
        { name: "考勤设置", id: 3, path: "/worktime" },
        { name: "职位等级设置", id: 4, path: "/positionlevel" },
      ],
      activenum: 1,
      ruleForm: {
        id: "",
        companyAvatar: "",
        companyName: "",
        shortName: "",
        companySize: "",
        companyType: "",
        companyWelfare: "",
        companyDescription: "",
      },
      contentNeedReview: {
        avatar: "",
        shortName: "",
        companySize: "",
        companyType: "",
        companyWelfare: [],
      },
      warnDialogVisible: false,
      sizeOptions: [],
      weifaryOptions: JSON.parse(sessionStorage.getItem("companyWelfare")),
      typeOptions: JSON.parse(sessionStorage.getItem("companyType")),
      updialogVisible: false,
    };
  },
  created() {
    this.getUserInfo();
    this.getSizeption();
  },
  activated() {
    this.getUserInfo();
    this.getSizeption();
    if(this.weifaryOptions === null || this.weifaryOptions.length === 0){
      this.getWelfareOptions();
    }
    if(this.typeOptions === null || this.typeOptions.length === 0){
      this.getTypeOptions();
    }
  },
  methods: {
    getUserInfo() {
      this.$api.getUserInfo("get").then((res) => {
        for (let k in this.ruleForm) {
          if (k == "companySize") {
            this.ruleForm[k] = res.data.company[k]
              ? Number(res.data.company[k].itemValue)
              : "";
          } else if (k == "companyWelfare") {
            let arr = [];
            if (res.data.company[k]) {
              res.data.company[k].map((item) => {
                arr.push(Number(item.itemValue));
              });
            }
            this.ruleForm.companyWelfare = arr;
          } else if (k == "companyType") {
            this.ruleForm[k] = res.data.company[k]
              ? Number(res.data.company[k].itemValue)
              : "";
          } else if (k == "companyAvatar") {
            this.fileList = [];
            this.ruleForm[k] = res.data.company[k];
            if (res.data.company[k]) {
              let obj = {
                url:"https://freemen.work/user/file/view/" + res.data.company[k],
              };
              this.fileList.push(obj);
            }
          } else {
            this.ruleForm[k] = res.data.company[k];
          }
        }
        this.contentNeedReview = {
          avatar: this.ruleForm.companyAvatar,
          shortName: this.ruleForm.shortName,
          companySize: this.ruleForm.companySize,
          companyType: this.ruleForm.companyType,
          companyWelfare: this.ruleForm.companyWelfare,
        };
      });
    },
    getTypeOptions() {
      //获取公司类型
      this.$api.getdict("get", { code: "company_type" }).then((res) => {
        let list = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        this.typeOptions = list;
      });
    },
    getWelfareOptions() {
      //获取公司福利
      this.$api.getdict("get", { code: "company_welfare" }).then((res) => {
        let list = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        this.weifaryOptions = list;
      });
    },
    getSizeption() {
      //获取公司规模
      this.$api.getdict("get", { code: "company_size" }).then((res) => {
        let list = [];
        res.data.map((item) => {
          let obj = {
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        this.sizeOptions = list;
      });
    },
    changetab(path) {
      // console.log(path);
      this.$router.push(path);
    },
    toSava(formName){
      this.warnDialogVisible = false;
      this.ruleForm.companyWelfare = "";
      this.ruleForm.companyWelfare = this.contentNeedReview.companyWelfare.join(",");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {};
          for (let k in this.ruleForm) {
            if (k != "companyName") {
              obj[k] = this.ruleForm[k];
            }
          }
          this.$api.saveUserInfo("post", obj).then(() => {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            this.getUserInfo();
          });
        } else {
          return false;
        }
      });
    },
    submitForm(formName) {
      // let flag = false;
      // if(this.ruleForm.companyWelfare !== this.contentNeedReview.companyWelfare) flag = true;
      // if(this.ruleForm.shortName !== this.contentNeedReview.shortName) flag = true;
      // if(this.ruleForm.companySize !== this.contentNeedReview.companySize) flag = true;
      // if(this.ruleForm.companyType !== this.contentNeedReview.companyType) flag = true;
      // if(this.ruleForm.companyAvatar !== this.contentNeedReview.avatar) flag = true;
      this.ruleForm.companyWelfare = this.contentNeedReview.companyWelfare;
      this.ruleForm.shortName = this.contentNeedReview.shortName;
      this.ruleForm.companySize = this.contentNeedReview.companySize;
      this.ruleForm.companyType = this.contentNeedReview.companyType;
      // if(flag){
      //   this.warnDialogVisible = true;
      // } else {
        this.toSava(formName);
      // }
    },
    handleSuccess(response, file, fileList) {
      this.fileList = [fileList[fileList.length - 1]];

      // console.log(response, file, fileList);
      this.ruleForm.companyAvatar = response.data;
    },
    handleRemove() {
      this.ruleForm.companyAvatar = "";
    },
    handlePictureCardPreview() {
      this.updialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.tab-list {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  div:hover {
    cursor: pointer;
  }
  div {
    margin-right: 30px;
    color: #666666;
  }
  .activeclass {
    color: #111111;
    position: relative;
    font-weight: 600;
  }
  .activeclass :after {
    position: absolute;
    content: "";
    width: 30px;
    height: 2px;
    bottom: -6px;
    background: #00bcff;
    left: 30%;
  }
}
/deep/.el-card {
  border-radius: 0px;
  border: none;
  box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
}
/deep/.el-card__body {
  padding: 80px 30px 160px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.demo-ruleForm {
  width: 700px;
  /deep/ .el-input__inner,
  /deep/ .el-textarea__inner {
    border-radius: 0px;
  }
  /deep/ .el-button {
    border-radius: 0px;
    width: 500px;
    margin-top: 50px;
    background: #00bcff;
    border: none;
  }
  /deep/ .el-upload--picture-card {
    width: 80px;
    height: 80px;
    border-radius: 0px;
  }
  /deep/ .el-upload {
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 0px;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 0px;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 80px;
    height: 80px;
    line-height: 80px;
    border-radius: 0px;
  }
  /deep/ .avatar {
    width: 80px;
    height: 80px;
    border-radius: 0px;
  }
  .imgts {
    color: #999999;
  }
}
</style>
